export default {
  items: [
    {
      title: true,
      name: "Genral",
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer"
    },
    {
      name: "Article",
      url: "/article",
      icon: "icon-note"
    },
    {
      name: "Daily Report BAPPEBTI",
      url: "/daily-report",
      icon: "icon-note"
    },
    {
      name: "Add Ons",
      url: "/addons",
      icon: "fa fa-chart",
      children: [
        {
          name: "Setup Add Ons",
          url: "/add-ons",
          icon: "icon-list"
        },
        {
          name: "Transaction History",
          url: "/transaction-addons",
          icon: "icon-list"
        }
      ]
    },
    {
      name: "Koin Nusantara",
      url: "/dinars",
      icon: "fa fa-money",
      children: [
        {
          name: "Produk",
          url: "/dinar-products",
          icon: "icon-list"
        },
        {
          name: "Transaksi Koin",
          url: "/dinar-orders",
          icon: "icon-list"
        },
        {
          name: "Pengaturan",
          url: "/dinar-settings",
          icon: "icon-list"
        }
      ]
    },
    {
      name: "Gold Reedem",
      url: "/gold-reedems",
      icon: "fa fa-exchange"
    },
    {
      name: "Jewelry",
      url: "/jewelries",
      icon: "fa fa-shopping-cart",
      children: [
        {
          name: "Jewelry List",
          url: "/jewelry-list",
          icon: "icon-puzzle"
        },
        {
          name: "Jewelry Cateogry",
          url: "/jewelry-categories",
          icon: "icon-puzzle"
        },
        // {
        //   name: 'Jewelry Discounts',
        //   url: 'jewelry-discounts',
        //   icon: 'icon-puzzle'
        // },
        {
          name: "Jewelry Order",
          url: "/jewelry-orders",
          icon: "icon-puzzle"
        }
      ]
    },
    {
      name: "Page",
      url: "/page",
      icon: "icon-layers"
    },
    {
      name: "Push Notification",
      url: "/push-notification",
      icon: "fa fa-bullhorn"
    },
    {
      name: "Shipping Cost RPX",
      url: "/shipping-cost",
      icon: "fa fa-truck"
    },
    {
      name: "System Error Logs",
      url: "/log-error",
      icon: "fa fa-warning"
    },
    {
      name: "Transaction History",
      url: "/transaction",
      icon: "icon-credit-card",
      children: [
        {
          name: "Gold Transaction",
          url: "/gold",
          icon: "icon-puzzle"
        }
      ]
    },
    {
      name: "User & ACL",
      url: "/user-acl",
      icon: "icon-user",
      children: [
        {
          name: "End User",
          url: "/end-user",
          icon: "icon-puzzle"
        },
        {
          name: "Permissions",
          url: "/permissions",
          icon: "icon-puzzle"
        },
        {
          name: "Roles",
          url: "/roles",
          icon: "icon-puzzle"
        },
        {
          name: "Teman Treasury",
          url: "/teman-treasury",
          icon: "icon-puzzle"
        },
        {
          name: "Super User",
          url: "/super-user",
          icon: "icon-puzzle"
        },
        {
          name: "Verifikasi KTP",
          url: "/verifikasi",
          icon: "icon-puzzle"
        },
        {
          name: "Verifikasi Partner",
          url: "/verify-partner",
          icon: "icon-puzzle"
        }
      ]
    },
    {
      name: "Cron Monitoring",
      url: "/cron-monitoring",
      icon: "fa fa-desktop"
    },
    {
      name: "Voucher",
      url: "/voucher",
      icon: "fa fa-percent"
    },
    {
      name: "Withdraw",
      url: "/withdraw",
      icon: "fa fa-upload"
    },
    {
      title: true,
      name: "Usership",
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: "Total User",
      url: "/total-user",
      icon: "fa fa-child"
    },
    {
      name: "Active User",
      url: "/active-user",
      icon: "fa fa-user",
      children: [
        {
          name: "Active User Buy Transaction",
          url: "/buy-transaction",
          icon: "icon-list"
        },
        {
          name: "Active User Sell Transaction",
          url: "/sell-transaction",
          icon: "icon-list"
        },
        {
          name: "Active User Gram Purchase",
          url: "/gram-purchase",
          icon: "icon-list"
        },
        {
          name: "Active User Money Spent",
          url: "/money-spent",
          icon: "icon-list"
        }
      ]
    },
    {
      name: "New User",
      url: "/new-user",
      icon: "fa fa-user-plus"
    },
    {
      name: "Silent User",
      url: "/silent-user",
      icon: "fa fa-user-secret"
    },
    {
      name: "Dead User",
      url: "/dead-user",
      icon: "fa fa-user-times"
    },
    {
      name: "Usership Setting",
      url: "/usership-setting",
      icon: "fa fa-gears"
    }
  ]
};
